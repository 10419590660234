import { SVGProps, Ref, forwardRef } from 'react'

const SvgColorFacebook = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fill="#1977F3"
      d="M22.662 11.83C22.662 5.85 17.812 1 11.83 1S1 5.85 1 11.83c0 5.406 3.96 9.887 9.139 10.7v-7.568H7.388v-3.131h2.75V9.444c0-2.714 1.618-4.214 4.092-4.214 1.185 0 2.424.211 2.424.211v2.666h-1.366c-1.345 0-1.765.834-1.765 1.692v2.032h3.003l-.48 3.131h-2.523v7.569c5.178-.812 9.139-5.293 9.139-10.7Z"
    />
    <path
      fill="#FEFEFE"
      d="m16.047 14.962.48-3.131h-3.003V9.799c0-.856.419-1.692 1.765-1.692h1.366V5.44s-1.24-.211-2.425-.211c-2.473 0-4.09 1.498-4.09 4.214v2.387H7.388v3.131h2.75v7.569a11.06 11.06 0 0 0 3.384 0v-7.569h2.524Z"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgColorFacebook)
export default ForwardRef
